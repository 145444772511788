
body{
  background-color: lightgray;
}

.MenuBar {
  display: flex;
  background-color: dimgray;
  justify-content: space-between;
}

.Input{
  display: flex;
  background-color: whitesmoke;
  border-radius: 5px;
  padding: 5px;
}

.Modal{
  display: flex;
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border: 2px solid #000;
  box-shadow:10px 5px 5px black;
  gap: 20px 20px;
  padding: 20px;
  flex-direction: column;
}

.RegisterModal{
  display: flex;
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: white;
  border: 2px solid #000;
  box-shadow:10px 5px 5px black;
  gap: 20px 20px;
  padding: 20px;
  flex-direction: column;
}
.MenuBox {
  display: flex;
  justify-content: right;

}
.menuItem{
  width: 100%;
  white-space: pre-wrap;
}
.menuContant{
  display: flex;
  justify-content: space-between;
}

.SolarTypeMenuBox{
  min-width:240px;
}

.default-margin{
  margin: 10px;
}

.Toastify__toast--error {
  background: #FAE1E2 !important;
}

.Start .Image {
  height: 10px;
  width: 10px;
  max-width: 10%;
  max-height: 20%;
}
.detailDashboard{
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.fakeAccordion{
  padding: 15px 5px 5px;
  background-color: white;
  border-radius: 5px;
  width: 100%;
  margin:auto;
}

@media (min-width: 500px) and (max-width: 1120px) {
  .DetailAccordion {
    width: 500px;
  }
}

@media (min-width: 1120px) and (max-width: 1630px){
  .DetailAccordion{
    width: 980px;
  }
}

@media (min-width: 1630px) {
  .DetailAccordion{
    width: 1490px;
  }
}

@media (min-width: 500px) {
  .defaultPanelWrapper{
    display: flex;
    flex-direction: column;
    width: 460px;
  }
}

@media (max-width: 500px) {
  .defaultPanelWrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.defaultFlowColumn{
  display: flex;
  flex-direction: column;
}

.panelContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.LogoutButton{
  display: flex;
  align-self: flex-end;
  justify-self: flex-end;
  justify-content: flex-end;
  align-content: flex-end;

}

.ManagerListElement{
  display:flex;
  justify-content:center;
  background-color:lightgray;
}

.DisabledMangerList{
  display: flex;
  pointer-events:none;
  background-color: gray;
}

.defaultFlex{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.marginCenterTopBottom{
  margin-top: auto;
  margin-bottom:  auto;
}

.flexRow{
  display: flex;
  flex-direction: row;
}

.flexColumnGap{
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.flexColumn{
  display: flex;
  flex-direction: column;
}

.flexRow{
  display: flex;
  flex-direction: row;
}

.marginAuto{
  margin-top: auto;
  margin-bottom: auto;
}

.totalValuesFontSize{
  font-size: 30px;
}

.totalValuesBox{
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.totalValuesBorderBox {
  border-color: black;
  border-style: solid;
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
}

.searchParamField{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  border: grey solid 3px;
  margin:10px;
}

.searchParamFieldFirst{
  display: flex;
  font-weight: bold;
  flex-direction: row;
  height: 40px;
}

.searchParamFieldSecond{
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
}


